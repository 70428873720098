/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/styles-variables.scss";


.row {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	width: 100%;
}

.col {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
}

.flex-spacer {
	flex: 1 1 auto;
	min-width: 0;
}

ion-input.ion-color-dark[color=dark] {
	color: var(--ion-color-dark-contrast);
}

.neon-button {
	height: 32pt;
	--background: linear-gradient(200deg, rgb(50, 170, 225), rgb(255, 0, 168));
	font-size: 14pt;
	font-weight: 900;
	text-transform: unset;

	margin: 10pt;
	width: calc(100% - 20pt);

	span {
		filter: drop-shadow(0 0 1px #ffffff40) drop-shadow(0 0 4px #b3e7ff);
	}
}

.code-copy{
    font-family: monospace;
    background: var(--ion-color-light);
    padding: 5px 8px;
    border-radius: 5px;
    line-height: 1.2;
    cursor: pointer;

    ion-icon{
        position: relative;
        top: 2px;
        margin-left: 4px;
    }

    &:active{
        user-select: all;
    }
}

ion-popover.flat::part(content) {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border: none;

	--max-width: calc(100% - 95px);
	--width: 100%;
}

searchbar {
	ion-searchbar {
		.searchbar-input-container {
			height: 44px !important;
		}
		ion-icon {
			display: none;
		}
		input {
			padding-inline-start: 10px !important;
		}
	}
}

.default-table {

	width: 100%;

	tr {
		width: 100%;
	}

	th {
		padding: 5px;
		text-align: left;

		&.right {
			text-align: right;
		}
	}

	td {
		padding: 5px 10px;
		text-align: left;

		&.right {
			text-align: right;
		}

		a {
			cursor: pointer;
		}
	}
}


.tableDiff {
    display: block;
    margin-bottom: 10pt;
    padding-top: 10px;

    .mat-table {
        margin-top: 0 !important;
        border-radius: 10px 10px 0 0;

        tbody{
            border-radius: inherit;

            .mat-row:first-of-type {
                border-radius: inherit;

                .mat-cell:first-of-type, .mat-cell:last-of-type {
                    border-radius: inherit;
                }
            }

            .mat-row {
                display: flex;
                display: -webkit-flex;
                margin-top: 5px;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    .tinyplus {
        color: #444444;
        font-size: 13pt;
        font-weight: 900;
    }

    .mat-header-cell {
        color: #888888;
        font-size: 14px;
        font-weight: 900;
        border-bottom-color: #BBBBBB;
        padding-bottom: 4px;
        text-align: center;
    }

    .mat-cell {
        text-align: center;
       // vertical-align: bottom;
        border: 0;
        width: 33%;

        &:first-of-type{
            padding-left: 0;
        }

        &:last-of-type{
            padding-right: 0;
        }

        &.na {
            span.unit {
                opacity: 0;
            }
        }
        vertical-align: bottom;

        .col-item {
            height: 5pt;

            &.difference-wrapper {
                padding-bottom: 17pt;
            }
        }

        .name {
            color: #000;
            font-size: 8pt;
            font-weight: 600;
            white-space: nowrap;
        }

        .difference-wrapper {
            .unit {
                color: #000;
                font-size: 10pt;
                font-weight: 400;
            }
        }

        .unit {
            color: #BBBBBB;
            font-size: 10pt;
            font-weight: 200;
            display: inline-block;
            text-align: left;
            margin-left: 3px;
            width: fit-content;
            min-width: 10pt;
        }

        .before, .after, .diff {
            font-size: 16pt;
            font-weight: 300;
        }

        .before {
            color: $color-left;
        }

        .after {
            color: $color-right;
        }

        .diff {
            color: #000;
            font-size: 10pt;
            font-weight: 400;
        }

        // body-comp
        &.column-name {
            font-size: 9pt;
            max-width: 200px;
            width: 25%;
        }

        &.column-valueBefore, &.column-valueAfter, &.column-difference {
            max-width: 120px;
            text-align: center;
        }

        .title {
            font-size: 14px;
            color: #444444;
        }

        .subtitle {
            font-size: 10px;
            font-weight: 400;
            color: #888888;
        }

        span {
            &.classification-title, &.blue{
                font-size: 14px;
                font-weight: 900;
                line-height: 16px;
            }

            &.classification-title {
                color: #444444;
            }

            &.blue {
                color: #32AAE1;
            }
        }
    }

    .mat-column-name, .column-name {
        font-size: 14px;
        font-weight: 900;
        color: #444444;
        text-align: left;
        padding-top: 5px;
    }

    .mat-row.spacer-row {
        height: 5pt;

        &.border-row {
            border-top: solid 1px #e3e3e3;
        }
        &.bottom-spacer-row {
            height: 20px;
        }
    }

    .mat-header-row {
        height: 10pt;
    }

    .mat-table {
        margin-top: 20pt;
        width: 100%;
    }
}

///////////////////////////////////////
// For Admin but needs to be in global:
///////////////////////////////////////

/* NGX Datatable CSS */
@import 'assets/styles/ngx-datatable/_index.scss';
@import 'assets/styles/ngx-datatable/_dark.scss';
@import 'assets/styles/ngx-datatable/_bodygeeIonic.scss';
@import 'assets/styles/ngx-datatable/_material.scss';
@import 'assets/styles/ngx-datatable/_bootstrap.scss';
@import 'assets/styles/ngx-datatable/_icons.css';

//.ngx-datatable.material {
//  .datatable-header {
//    .datatable-header-cell {
//      //font-family: 'Roboto';
//      //font-style: normal;
//      //font-weight: 400;
//      //font-size: 12px;
//      //line-height: 16px;
//    }
//  }
//}
