@import "../../../theme/variables.scss";

.ngx-datatable.bodygeeIonic {
  box-shadow: none;
  background: var(--ion-background-color);
  border: 0px solid var(--ion-color-step-150);
  color: var(--ion-text-color);
  font-size: 14px;
  height: calc(100vh - 102px);

  .datatable-header {
    background: var(--ion-color-step-50);
    color: var(--ion-color-step-550);

    .datatable-header-cell {
      text-align: left;
      padding: 0.5rem 1.2rem;
      font-family: inherit;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      .datatable-header-cell-label {
        line-height: 24px;
      }
    }
  }

  .datatable-body {
    background: var(--ion-item-background);

    .datatable-body-row {
      border-top: 0.5px solid var(--ion-color-step-250);

      .datatable-body-cell {
        text-align: left;
        padding: 0.5rem 1.2rem;
        display: flex;
        align-items: center;
      }

      ion-badge{
        display: flex;
      }

      .avatarImg{
        border-radius: 999px;
        overflow: hidden;
      }

      &:hover {
        background: var(--ion-color-step-100);
        transition-property: background;
        transition-duration: 0.1s;
        transition-timing-function: linear;
        cursor: pointer;
      }

      &:focus {
        background-color: var(--ion-color-step-400);
      }

      &.active {
        background-color: var(--ion-color-primary);
        color: var(--ion-text-color);
      }
    }
  }

  .datatable-footer {
    background: var(--ion-color-step-150);
    color: var(--ion-color-step-550);
    margin-top: -1px;

    .page-count {
      line-height: 30px;
      height: 30px;
      padding: 0 1.2rem;
      span {
      }
    }

    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;

      ul {
        li {
          margin: 10px 0px;

          &:not(.disabled) {
            &.active a,
            &:hover a {
              background-color: var(--ion-color-step-300);
              font-weight: bold;
            }
          }
        }
      }

      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: var(--ion-color-step-800);
      }

      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }

  .datatable-summary-row {
    .datatable-body-row {
      background-color: var(--ion-color-step-100);

      &:hover {
        background-color: var(--ion-color-step-100);
      }

      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}
