$bodygee-blue: rgb(50, 170, 225);
$light-blue: #DFF6FE;
$primary: rgb(120, 180, 88);
$bodygee-secondary: rgb(224, 211, 199);

$login-green: #78B458;

$color-left: #FF00A8;
$color-right: #32AAE1;
$color-diff: --ion-color-darkest;
$gradient-pink: #FF00A8;
$gradient-blue: #32AAE1;
$gradient-grey: #242729;
$glow-size: 3px;
$glow-blur: 6px;
$glow-gradient: linear-gradient(90deg, #ff8ed9 0%, #73d3ff 100%);
$text-glow-blue: drop-shadow(0 0 1px #ffffff80) drop-shadow(0 0 3px #b3e7ff);
$text-glow-pink: drop-shadow(0 0 1px #ffffff80) drop-shadow(0 0 3px #f75159);
$text-glow-blue-strong: $text-glow-blue drop-shadow(0 0 5px #85d8ff);

$min-bt-height: 44px;
$toolbar-height: 50px;

$border-radius: 13px;
$body-selector-height: 54px;

$detail-page-width: 40%;

$color-white: #fff;
$color-black: #000;
